import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { EuiButton, EuiButtonIcon, EuiGlobalToastList } from "@elastic/eui";

import DataGrid from "../../components/DataGrid/DataGrid";
import { useHistoryGridData } from "../../core/store/data/history-provider";
import {
  historyDataGridColumns,
  historyDataGridDefaultColumns,
} from "./utils/HistoryGridConfig";
import moment from "moment";

function History() {
  const historyProvider = useHistoryGridData();

  useEffect(() => {
    historyProvider.fetchHistory();
    historyProvider.setToasts([]);
  }, []);

  const onRefreshBtnClick = (): void => {
    historyProvider.fetchHistory();
  };

  if (!localStorage.getItem("auth")) {
    console.log("redirect");
    return <Navigate to="/" replace />;
  }

  const downloadBtn = ({ rowIndex }: { rowIndex: number }): JSX.Element => {
    const { documentId, createdAt } = historyProvider.historyData[rowIndex];
    const downloadName: string = `Advances_${moment(createdAt).format(
      "YYYY-MM-DD"
    )}`;

    return (
      <EuiButtonIcon
        color="text"
        iconType="download"
        iconSize="m"
        aria-label="Download"
        onClick={() =>
          historyProvider.downloadHistoryDocument(documentId, downloadName)
        }
      />
    );
  };

  return (
    <div>
      <EuiGlobalToastList
        toasts={historyProvider.toasts}
        dismissToast={() => historyProvider.setToasts([])}
        toastLifeTimeMs={10000}
      />

      <DataGrid
        data={historyProvider.historyData}
        columns={historyDataGridColumns}
        defaultColumns={historyDataGridDefaultColumns}
        trailingControlColumns={[
          {
            id: "actions",
            width: 40,
            headerCellRender: () => null,
            rowCellRender: downloadBtn,
          },
        ]}
      />

      <EuiButtonIcon
        onClick={onRefreshBtnClick}
        iconType="refresh"
        aria-label="refresh"
        display="base"
      ></EuiButtonIcon>
    </div>
  );
}

export default History;
