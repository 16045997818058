import { EuiDataGridColumn } from "@elastic/eui";

import { PayrollInfoFields, PayrollInfoHeaders } from "../../../core/enums/PayrollInfo";

export const payrollInfoDataGridColumns: EuiDataGridColumn[] = [
  {
    id: PayrollInfoFields.PHONE_NUMBER,
    displayAsText: PayrollInfoHeaders.PHONE_NUMBER,
  },
  {
    id: PayrollInfoFields.CLIENT_NOTE,
    displayAsText: PayrollInfoHeaders.CLIENT_NOTE,
  },
  {
    id: PayrollInfoFields.REFERENCE_PERIOD,
    displayAsText: PayrollInfoHeaders.REFERENCE_PERIOD,
  },
  {
    id: PayrollInfoFields.PAYROLL,
    displayAsText: PayrollInfoHeaders.PAYROLL,
  },
  {
    id: PayrollInfoFields.MAX_ADVANCE,
    displayAsText: PayrollInfoHeaders.MAX_ADVANCE,
  },
  {
    id: PayrollInfoFields.FULL_NAME,
    displayAsText: PayrollInfoHeaders.FULL_NAME,
  },
  {
    id: PayrollInfoFields.PERSONAL_ID,
    displayAsText: PayrollInfoHeaders.PERSONAL_ID,
  },
  {
    id: PayrollInfoFields.IBAN,
    displayAsText: PayrollInfoHeaders.IBAN,
  },
  {
    id: PayrollInfoFields.STATUS,
    displayAsText: PayrollInfoHeaders.STATUS,
  },
];

export const payrollInfoDataGridDefaultColumns: string[] = [
  PayrollInfoFields.PHONE_NUMBER,
  PayrollInfoFields.FULL_NAME,
  PayrollInfoFields.PAYROLL,
  PayrollInfoFields.MAX_ADVANCE,
  PayrollInfoFields.REFERENCE_PERIOD,
];
