import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { EuiButton, EuiIcon, EuiTab, EuiTabs } from "@elastic/eui";

import { useAuth } from "../../core/store/auth/use-auth";

const Navbar = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [isSelected, setIsSelected] = useState<string>('/home');

  useEffect(() => {
    if (location.pathname === '/home') {
      setIsSelected('/home');
    } 
    if (location.pathname === '/history') {
      setIsSelected('/history');
    }
  }, [location.pathname]);

  const onLogoutBtnClick = (): void => {
    auth.signOut();
    navigate("/");
  };

  return localStorage.getItem("auth") ? (
    <>
      <EuiTabs 
        size='m' 
        style={{ 
          alignItems: 'center'
        }}
      >
        <EuiTab
          onClick={() => {
            setIsSelected('/home');
            navigate("/home");
          }}
          prepend={<EuiIcon type="list" />}
          isSelected={isSelected === '/home'}
        >
          Upload
        </EuiTab>
        <EuiTab
          onClick={() => {
            setIsSelected('/history');
            navigate("/history");
          }}
          prepend={<EuiIcon type="list" />}
          isSelected={isSelected === '/history'}
        >
          History
        </EuiTab>
        <EuiButton
          onClick={onLogoutBtnClick}
          iconType="exit"
          style={{ marginLeft: "auto", marginRight: '0.125rem' }}
        >
          LOGOUT
        </EuiButton>
      </EuiTabs>
    </>
  ) : (
    <></>
  );
};

export default Navbar;
