import React from "react";

import "./App.scss";
import ROUTES, { RenderRoutes } from "./routes";
import Navbar from "./components/Navbar/Navbar";
import { AuthProvider } from "./core/store/auth/use-auth";

const App = () => {
  return (
    <AuthProvider>
      <div className="App">
        <Navbar />
        <RenderRoutes routes={ROUTES} />
      </div>
    </AuthProvider>
  );
};

export default App;