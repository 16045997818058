import React from "react";

import { EuiFilePicker } from "@elastic/eui";
import { IFilePickerProps } from "./IFilePickerProps";

const FilePicker: React.FC<IFilePickerProps> = (props: IFilePickerProps) => (
  <EuiFilePicker
    initialPromptText="select file"
    fullWidth
    onChange={props.handleChange}
  />
);

export default FilePicker;
