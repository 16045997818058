import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import Home from './containers/home/Home';
import History from './containers/history/History';
import Login from "./containers/login/Login";
import { RouteModel } from "./core/models/Route.model";
import { GridDataProvider } from "./core/store/data/data-provider";
import { HistoryDataProvider } from "./core/store/data/history-provider";

/**
 * Render a route with potential sub routes
 * https://reacttraining.com/react-router/web/example/route-config
 */
// TODO: adjust sub routes to react-router v6
//  const RouteWithSubRoutes = (route: Route) => {
//    console.log('routeWithSubRoutes: ', route);
//   return (
//     <Route
//       // key={route.key}
//       path={route.path}
//       element={<Empty />}
      
//       // element={<Route children={route.routes} />}
//       // render={props => <route.component {...props} routes={route.routes} />}
//     />
//   );
// };

/**
 * Use this component for any new section of routes (any config object that has a "routes" property
 */
export const RenderRoutes = ({ routes }: { routes: RouteModel[] }) => {
  // TODO: maybe render just the "/" route when the user in not logged in??
  return (
    <Routes>
      {routes.map((route: RouteModel) => {
        // return <RouteWithSubRoutes key={route.key} {...route} />;
        return <Route {...route} />;
      })}
      {/* TODO: navigate to / and redirect if logged in? */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

const ROUTES: RouteModel[] = [
  { 
    path: "/", 
    key: "ROOT", 
    element: <Login />
  },
  {
    path: "/home",
    key: "HOME",
    element: <GridDataProvider><Home /></GridDataProvider>,
    // TODO: uncomment and try if sub routes get configured
    // routes: [
    //   {
    //     path: "/upload",
    //     key: "HOME_UPLOAD",
    //     element: <Empty />,
    //   },
    //   {
    //     path: "/overview",
    //     key: "HOME_OVERVIEW",
    //     element: <Empty />
    //   },
    // ],
  },
  {
    path: "/history",
    key: "HISTORY",
    element: <HistoryDataProvider><History /></HistoryDataProvider>,
  }
];

export default ROUTES;