export enum PayrollInfoFields {
  PHONE_NUMBER = 'phoneNumber',
  CLIENT_NOTE = "clientNote",
  REFERENCE_PERIOD = 'referencePeriod',
  PAYROLL = 'payroll',
  MAX_ADVANCE = 'maxAdvance',
  FULL_NAME = 'fullName',
  PERSONAL_ID = 'personalId',
  IBAN = 'iban',
  STATUS = 'status'
}

export enum PayrollInfoHeaders {
  PHONE_NUMBER = 'Phone Number',
  CLIENT_NOTE = "Client Note",
  REFERENCE_PERIOD = 'Reference Period',
  PAYROLL = 'Payroll',
  MAX_ADVANCE = 'Max Advance',
  FULL_NAME = 'Full Name',
  PERSONAL_ID = 'Personal ID',
  IBAN = 'IBAN',
  STATUS = 'Status'
}