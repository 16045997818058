import React, { createContext, useContext, useState } from "react";

import axios from "axios";
import fileDownload from "js-file-download";
import { Toast } from "@elastic/eui/src/components/toast/global_toast_list";

import { PayrollInfo } from "../../models/PayrollInfo.model";
import { API_URL } from "../../../http/index";
import toastList from "../../../containers/history/utils/Toasts";

export const HistoryDataContext = createContext<any>([]);

export const HistoryDataProvider = ({ children }: { children: any }) => {
  const data = useHistoryProvideData();

  return (
    <HistoryDataContext.Provider value={data}>
      {children}
    </HistoryDataContext.Provider>
  );
};

export const useHistoryGridData = () => {
  return useContext(HistoryDataContext);
};

const useHistoryProvideData = () => {
  const [historyData, setHistoryData] = useState<PayrollInfo[] | null>([]);
  const [errors, setErrors] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toasts, setToasts] = useState<Toast[]>([]);

  const token = localStorage.getItem("auth");

  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };  

  function fetchHistory() {    
    setHistoryData([]);
    setErrors([]);
    setIsLoading(true);

    axios
      .get(
        `${API_URL}/api/v1/business-admin/history/all/?api-key=y061t00&field=createdAt&order=DESC`,
        { headers: headers }
      )
      .then(function (response) {
        setIsLoading(false);
        if (response.data.success) {
          setHistoryData(response.data.historyData);
          setToasts([toastList.loadHistorySuccess]);
        } else {
          setToasts([toastList.loadHistoryFailed]);
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        setToasts([toastList.loadHistoryFailed]);
        console.log("error: ", error);
        setErrors(error);
      });
  }

  const downloadHeaders = {
    "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    Authorization: `Bearer ${token}`,
  };  

  function downloadHistoryDocument(id: number, name: string) {
    axios
      .get(
        `${API_URL}/api/v1/business-admin/history/download/?api-key=y061t00&id=${id}`,
        { 
          headers: downloadHeaders,
          responseType: 'blob' 
        }
      )
      .then((response: any) => {
        if (response.success) {
          fileDownload(response.data, `${name}.xlsx`);
          setToasts([toastList.downloadHistorySuccess]);
        } else {
          setToasts([toastList.downloadHistoryFailed]);
        }
      })
      .catch(err => {
        console.log('error: ', err);
        setToasts([toastList.downloadHistoryFailed]);
      });
  }

  return {
    historyData,
    setHistoryData,
    errors,
    isLoading,
    toasts,
    setToasts,
    fetchHistory,
    downloadHistoryDocument
  };
};
