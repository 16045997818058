export enum HistoryFields {
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  ACCOUNT_ID = 'accountId',
  PHONE_NUMBER = 'phoneNumber',
  CLIENT_NOTE = "clientNote",
  REFERENCE_PERIOD = 'referencePeriod',
  STATUS = 'status'
}

export enum HistoryHeaders {
  CREATED_AT = 'Created At',
  UPDATED_AT = 'Updated At',
  ACCOUNT_ID = '(Uploader) Account ID',
  PHONE_NUMBER = 'Phone Number',
  CLIENT_NOTE = "Client Note",
  REFERENCE_PERIOD = 'Reference Period',
  STATUS = 'Status'
}