import React, { createRef, Ref, useEffect, useState } from "react";

import { EuiDataGrid, EuiDataGridCellValueElementProps, EuiDataGridRefProps } from "@elastic/eui";

import { IDataGridProps } from "./IDataGridProps";
import moment from "moment";

const DataGrid: React.FC<IDataGridProps> = (props: IDataGridProps) => {
  const gridRef: Ref<EuiDataGridRefProps> = createRef();
  
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);

  useEffect(() => {
    if (!props.defaultColumns) return;
    setVisibleColumns(props.defaultColumns);
  }, [props.defaultColumns]);

  const renderCellValue = ({ rowIndex, columnId, schema }: EuiDataGridCellValueElementProps) => {
    if (schema === 'datetime') {
      return moment(props.data[rowIndex][columnId]).format('YYYY-MM-DD HH:mm');
    }

    return props.data[rowIndex][columnId];
  };

  return (
      <EuiDataGrid
        columns={props.columns}
        columnVisibility={{ visibleColumns, setVisibleColumns }}
        rowCount={props.data.length}
        renderCellValue={renderCellValue}
        aria-label="data-grid"
        ref={gridRef}
        trailingControlColumns={props.trailingControlColumns}
      />
  );
};

export default DataGrid;
