import { Toast } from "@elastic/eui/src/components/toast/global_toast_list";

const toastList: { [key: string]: Toast } = {
  signIn: {
    id: 'success-signin',
    title: 'Welcome to Yoshi Client Portal!',
    color: 'success',
    iconType: 'check'
  },
  signOut: {
    id: 'success-signout',
    title: 'You have beed logged out.',
    color: 'success',
    iconType: 'check',
    text: 'See you soon!'
  },
  uploadSuccess: {
    id: 'success-upload',
    title: 'Upload complete!',
    color: 'success',
    iconType: 'check',
    text: 'The employees list was successfully uploaded!'
  },
  uploadFailed: {
    id: 'fail-upload',
    title: 'Oops, there was an error.',
    color: 'danger',
    iconType: 'alert',
    text: 'Sorry. We will try not to let it happen it again.'
  }
};

export default toastList;