import React, { useEffect, useState } from "react";

import { Navigate } from "react-router-dom";
import { EuiButton, EuiGlobalToastList } from "@elastic/eui";
import moment from "moment";

import FilePicker from "../../components/FilePicker/FilePicker";
import DataGrid from "../../components/DataGrid/DataGrid";
// import { PayrollInfo } from "../../core/models/PayrollInfo.model";
import {
  payrollInfoDataGridColumns,
  payrollInfoDataGridDefaultColumns,
} from "./utils/PayrollInfoConfig";
import { useGridData } from "../../core/store/data/data-provider";

const Home: React.FC = () => {
  const dataProvider = useGridData();

  const [fileToUpload, setFileToUpload] = useState<FileList | null>(null);
  const [isUploadBtnDisabled, setIsUploadBtnDisabled] = useState<boolean>(true);
  // const [uploadedData, setUploadedData] = useState<PayrollInfo[]>([]);

  // useEffect(() => {
  //   if (!dataProvider.uploadedData.length) return;
  //   setUploadedData(dataProvider.uploadedData);
  // }, [dataProvider.uploadedData]);

  const handleFilePickerChange = (files: FileList | null): void => {
    const isFileSelected: boolean = !!files?.length;
    setFileToUpload(isFileSelected ? files : null);
    setIsUploadBtnDisabled(!isFileSelected);
  };

  const handleUploadBtnClick = (): void => {
    const currentReferencePeriod: string = moment(new Date()).format("YYYYMM");
    dataProvider.uploadFile(currentReferencePeriod, fileToUpload);
  };

  if (!localStorage.getItem("auth")) {
    console.log("redirect");
    return <Navigate to="/" replace />;
  }

  return (
    <div>
       <EuiGlobalToastList
        toasts={dataProvider.toasts}
        dismissToast={() => dataProvider.setToasts([])}
        toastLifeTimeMs={10000}
      />

      {/* Header */}
      <FilePicker handleChange={handleFilePickerChange} />
      <EuiButton
        isDisabled={isUploadBtnDisabled}
        onClick={handleUploadBtnClick}
        fullWidth
      >
        Upload
      </EuiButton>

      {/* Content */}
      <DataGrid
        data={dataProvider.uploadedData}
        columns={payrollInfoDataGridColumns}
        defaultColumns={payrollInfoDataGridDefaultColumns}
      />
    </div>
  );
};

export default Home;
