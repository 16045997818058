import { Toast } from "@elastic/eui/src/components/toast/global_toast_list";

const historyToasts: { [key: string]: Toast } = {
  loadHistorySuccess: {
    id: 'success-history-load',
    title: 'Loading complete!',
    color: 'success',
    iconType: 'check',
    text: 'The upload history was successfully loaded!'
  },
  loadHistoryFailed: {
    id: 'fail-history-load',
    title: 'Oops, there was an error.',
    color: 'danger',
    iconType: 'alert',
    text: 'Sorry. We will try not to let it happen it again.'
  },
  downloadHistorySuccess: {
    id: 'success-history-load',
    title: 'Download complete!',
    color: 'success',
    iconType: 'check',
    text: 'The history file was successfully downloaded!'
  },
  downloadHistoryFailed: {
    id: 'fail-history-load',
    title: 'Oops, there was an error.',
    color: 'danger',
    iconType: 'alert',
    text: 'Sorry. We will try not to let it happen it again.'
  }
};

export default historyToasts;