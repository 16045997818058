import React, { createContext, useContext, useState } from "react";

import axios from "axios";

import { PayrollInfo } from "../../models/PayrollInfo.model";
import { API_URL } from "../../../http/index";
import { Toast } from "@elastic/eui/src/components/toast/global_toast_list";
import toastList from "../../../containers/home/utils/Toasts";

export const GridDataContext = createContext<any>([]);

export const GridDataProvider = ({ children }: { children: any }) => {
  const data = useProvideData();

  return (
    <GridDataContext.Provider value={data}>{children}</GridDataContext.Provider>
  );
};

export const useGridData = () => {
  return useContext(GridDataContext);
};

const useProvideData = () => {
  const [uploadedData, setUploadedData] = useState<PayrollInfo[] | null>([]);
  const [errors, setErrors] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toasts, setToasts] = useState<Toast[]>([]);

  const token = localStorage.getItem("auth");

  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };

  function uploadFile(referencePeriod: string, files: FileList) {
    setUploadedData([]);
    setErrors([]);
    setIsLoading(true);

    const formData = new FormData();

    formData.append("referencePeriod", referencePeriod);
    if (files) {
      formData.append("file", files[0]);
    }

    axios
      .post(
        `${API_URL}/api/v1/business-admin/upload/?api-key=y061t00`,
        formData,
        { headers: headers }
      )
      .then(function (response) {
        setIsLoading(false);
        if (response.data.success) {
          setToasts([toastList.uploadSuccess]);
        } else {
          let errorToast: Toast = toastList.uploadFailed;
          if (!response.data.parsing.success) {
            const { 
              message = 'Error', 
              type = 'Server', 
              expected = 'successful upload', 
              actual = 'upload failed', 
              atRow = '',
              atColumn = ''
            } = response.data.parsing.error;

            errorToast = {
              ...errorToast,
              title: `${type} error`,
              text: `${message} ${atRow ? ('Check table row: ' + atRow) : ''}; 
                    ${atColumn ? ('column: ' + atColumn) : ''}; 
                    Expected: ${expected}; Actual: ${actual};`
            }
          }
          setToasts([errorToast]);
        }
        setUploadedData(
          response.data.payrolls.data.map((entry: any) => entry.payroll)
        );
      })
      .catch(function (error) {
        setIsLoading(false);
        setToasts([toastList.uploadFailed]);
        console.log("error: ", error);
        setErrors([error]);
      });
  }

  return {
    uploadedData,
    errors,
    isLoading,
    toasts,
    uploadFile,
    setUploadedData,
    setToasts
  };
};
