import { EuiDataGridColumn } from "@elastic/eui";

import { HistoryFields, HistoryHeaders } from "../../../core/enums/History";

export const historyDataGridColumns: EuiDataGridColumn[] = [
  {
    id: HistoryFields.CREATED_AT,
    displayAsText: HistoryHeaders.CREATED_AT,
    schema: 'datetime'
  },
  {
    id: HistoryFields.UPDATED_AT,
    displayAsText: HistoryHeaders.UPDATED_AT,
    schema: 'datetime'
  },
  {
    id: HistoryFields.REFERENCE_PERIOD,
    displayAsText: HistoryHeaders.REFERENCE_PERIOD
  },
  {
    id: HistoryFields.STATUS,
    displayAsText: HistoryHeaders.STATUS,
  },
  {
    id: 'fullName',
    displayAsText: `Uploader's name`
  },
  {
    id: 'emailAddress',
    displayAsText: `Uploader's e-mail`
  },
  {
    id: 'entries',
    displayAsText: 'Imported employees'
  },
  {
    id: 'totalAdvances',
    displayAsText: 'Total advances'
  },
  {
    id: 'approvedAdvances',
    displayAsText: 'Approved advances'
  }
];

export const historyDataGridDefaultColumns: string[] = [
  HistoryFields.CREATED_AT,
  HistoryFields.UPDATED_AT,
  HistoryFields.REFERENCE_PERIOD,
  HistoryFields.STATUS
];