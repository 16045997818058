import React, { useEffect, useState } from "react";

import { Navigate, useNavigate } from "react-router-dom";
import {
  EuiButton,
  EuiFieldPassword,
  EuiFieldText,
  EuiForm,
  EuiFormControlLayout,
  EuiFormRow,
  EuiGlobalToastList,
  EuiPageHeader
} from "@elastic/eui";

import { useAuth } from "../../core/store/auth/use-auth";

const Login: React.FC = () => { 
  const auth = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoginBtnDisabled, setIsLoginBtnDisabled] = useState<boolean>(true);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  useEffect(() => {
    setIsInvalid(!!auth.errors.length);
  }, [auth.errors]);

  useEffect(() => {
    if (!auth.user) return;
    console.log('redirect');
    navigate('/home');
  }, [auth.user]);

  useEffect(() => {
    const isCredentialEmpty: boolean = !username.length || !password.length;
    setIsLoginBtnDisabled(isCredentialEmpty);
  }, [username, password]);  

  const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
    setIsInvalid(false);
  };

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setIsInvalid(false);
  };

  const onEnterClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter" || isLoginBtnDisabled) return;
    handleLoginBtnClick(e);
  };

  const handleLoginBtnClick = (e: any): void => {
    e.preventDefault();
    auth.signIn(username, password);
  };

  if (localStorage.getItem('auth')) {
    return <Navigate to="/home" replace />;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '20vw',
        height: '100vh',
        margin: '0 auto'
      }}
    >
      <EuiGlobalToastList
        toasts={auth.toasts}
        dismissToast={() => auth.setToasts([])}
        toastLifeTimeMs={5000}
      />
      <EuiPageHeader 
        pageTitle="Yoshi Back Office"
        paddingSize="l"
        style={{
          textAlign: 'center'
        }}
      />
      <EuiForm component="form">
        <EuiFormRow label="Username">
          <EuiFormControlLayout
            isLoading={auth.isLoading}
            isInvalid={isInvalid}
          >
            <EuiFieldText
              value={username}
              onChange={onUsernameChange}
              onKeyDown={onEnterClick}
              isInvalid={isInvalid}
              autoComplete="on"
            />
          </EuiFormControlLayout>
        </EuiFormRow>

        <EuiFormRow label="Password">
          <EuiFormControlLayout
            isLoading={auth.isLoading}
            isInvalid={isInvalid}
          >
            <EuiFieldPassword
              value={password}
              onChange={onPasswordChange}
              onKeyDown={onEnterClick}
              isInvalid={isInvalid}
              autoComplete="on"
            />
          </EuiFormControlLayout>
        </EuiFormRow>

        <EuiButton
          type="submit"
          isDisabled={isLoginBtnDisabled || auth.isLoading}
          onClick={handleLoginBtnClick}
          iconType='check'
          fullWidth
          fill
        >
          LOGIN
        </EuiButton>
      </EuiForm>
    </div>
  );
};

export default Login;
