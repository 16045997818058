import React, { createContext, useContext, useState } from "react";

import axios from "axios";

import { API_URL } from "../../../http/index";
import { Toast } from "@elastic/eui/src/components/toast/global_toast_list";
import toastList from "../../../containers/home/utils/Toasts";

export const AuthContext = createContext<any>({});
// <{sucess: boolean, token: string, user: { username: string, userId: number }}>

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const AuthProvider = ({ children }: { children: any }) => {
  const auth = useProvideAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(AuthContext);
};

const useProvideAuth = () => {
  const [user, setUser] = useState<{ username: string; userId: number } | null>(null);
  const [errors, setErrors] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toasts, setToasts] = useState<Toast[]>([]);

  function signIn(username: string, password: string) {
    setUser(null);
    setErrors([]);
    setIsLoading(true);

    const loginCredentials: {
      username: string;
      password: string;
    } = {
      username: username,
      password: password,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .put(
        `${API_URL}/api/v1/business-admin/login`,
        loginCredentials,
        { headers: headers }
      )
      .then(response => {
        setIsLoading(false);
        localStorage.setItem("auth", response.data.token);
        setUser(response.data.user);
        setToasts([toastList.signIn]);
      })
      .catch(error => {
        setIsLoading(false);
        setErrors(['Unauthorized. Please log in again.']);
      });
  }

  function signOut() {
    localStorage.removeItem('auth');
    setUser(null);
    setToasts([toastList.signOut]);
  }

  return {
    user,
    errors,
    isLoading,
    toasts,
    signIn,
    signOut,
    setToasts
  }
};
